////
//// Typography
////

$body-font-family:  roboto, sans-serif;
// $mono-font-family: "SFMono-Regular", Menlo, Consolas, Monospace;
$root-font-size: 15px;         // Base font-size for rems
//$body-line-height: 1.4;
//$content-line-height: 1.5;
//$body-heading-line-height: 1.15;

////
//// Colors
////

//$white: #fff;

$grey-dk-000: #959396;
$grey-dk-100: #3e3b42;
$grey-dk-200: #44434d;
//$grey-dk-250: #302d36;
//$grey-dk-300: #606061;

//$grey-lt-000: #f5f6fa;
//$grey-lt-100: #eeebee;
//$grey-lt-200: #ecebed;
//$grey-lt-300: #e6e1e8;

//$purple-000: #7253ed;
//$purple-100: #5e41d0;
//$purple-200: #4e26af;
//$purple-300: #381885;

$blue-000: #2c84fa;
//$blue-100: #2869e6;
//$blue-200: #264caf;
//$blue-300: #183385;

//$green-000: #41d693;
//$green-100: #11b584;
//$green-200: #009c7b;
//$green-300: #026e57;

//$yellow-000: #ffeb82;
//$yellow-100: #fadf50;
//$yellow-200: #f7d12e;
//$yellow-300: #e7af06;

//$red-000: #f77e7e;
//$red-100: #f96e65;
//$red-200: #e94c4c;
//$red-300: #dd2e2e;

//$body-background-color: $white;
$sidebar-color: $white;
//$search-background-color: $white;
//$table-background-color: $white;
//$code-background-color: $grey-lt-000;
//$code-background-color: #f9f9f9;
$code-background-color: #30302f;

//$body-text-color: $grey-dk-100;
//$body-heading-color: $grey-dk-100;
//$search-result-preview-color: $grey-dk-000;
//$nav-child-link-color: $grey-dk-100;
//$link-color: $purple-000;
$link-color: $blue-000;
//$btn-primary-color: $purple-100;
//$base-button-color: #f7f7f7;

////
//// Spacing
////

//$spacing-unit: 1rem; // 1rem == 16px

//$spacers: (
//sp-0: 0,
//sp-1: $spacing-unit * 0.25,
//sp-2: $spacing-unit * 0.5,
//sp-3: $spacing-unit * 0.75,
//sp-4: $spacing-unit,
//sp-5: $spacing-unit * 1.5,
//sp-6: $spacing-unit * 2,
//sp-7: $spacing-unit * 2.5,
//sp-8: $spacing-unit * 3,
//sp-9: $spacing-unit * 3.5,
//sp-10: $spacing-unit * 4
//);

//$sp-1: map-get($spacers, sp-1); // 0.25 rem == 4px
//$sp-2: map-get($spacers, sp-2); // 0.5  rem == 8px
//$sp-3: map-get($spacers, sp-3); // 0.75 rem == 12px
//$sp-4: map-get($spacers, sp-4); // 1    rem == 16px
//$sp-5: map-get($spacers, sp-5); // 1.5  rem == 24px
//$sp-6: map-get($spacers, sp-6); // 2    rem == 32px
//$sp-7: map-get($spacers, sp-7); // 2.5  rem == 40px
//$sp-8: map-get($spacers, sp-8); // 3    rem == 48px
//$sp-9: map-get($spacers, sp-9); // 4    rem == 48px
//$sp-10: map-get($spacers, sp-10); // 4.5  rem == 48px

////
//// Borders
////

//$border: 1px solid;
//$border-radius: 4px;
//$border-color: $grey-lt-100;

////
//// Grid system
////

//$gutter-spacing: $sp-6;
//$gutter-spacing-sm: $sp-4;
$nav-width: 300px;
$nav-width-md: 270px;
$content-width: 1200px;
//$header-height: 60px;
//$search-results-width: 500px;

////
//// Media queries in pixels
////

$media-queries: (
    xs: 320px,
    sm: 500px,
    md: 800px,
    lg: $content-width + $nav-width,
    xl: 1400px
);
