//
// The basic two column layout
//

.page-wrap {
  @include mq(md) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.side-bar {
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  background-color: #fcfcfc;

  @include mq(md) {
    flex-wrap: nowrap;
    position: absolute;
    width: $nav-width-md;
    height: 100%;
    flex-direction: column;
    border-right: $border $border-color;
    align-items: flex-end;
  }

  @include mq(lg) {
    width: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
    min-width: $nav-width;
  }
}

.main-content-wrap {
  @include mq(md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.main-content {
  @include mq(md) {
    position: relative;
    max-width: $content-width;
    margin-left: $nav-width-md;
  }

  @include mq(lg) {
    margin-left: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
  }
}

.js-main-content:focus {
  outline: none;
}
.page-content:focus {
  outline: none;
}

.page {
  @include container;
  padding-top: $gutter-spacing-sm;
  padding-bottom: $gutter-spacing-sm;

  @include mq(md) {
    padding-top: $gutter-spacing;
    padding-bottom: $gutter-spacing;
  }
}

.page-header {
  @include container;
  display: inline-block;
  // padding-top: $gutter-spacing-sm;
  // padding-bottom: $gutter-spacing-sm;
  background-color: $sidebar-color;

  @include mq(md) {
    display: block;
    // justify-content: flex-start;
    height: $header-height;
    background-color: $sidebar-color;
    border-bottom: $border $border-color;
  }

  &.nav-open {
    display: inline-block;

    @include mq(md) {
      display: flex;
    }
  }
}

.navigation,
.site-header,
.site-footer {
  width: 100%;

  @include mq(lg) {
    width: $nav-width;
  }
}

.navigation {
  @include container;

  @include mq(md) {
    padding-top: $sp-8;
    padding-bottom: $gutter-spacing-sm;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}

.site-header {
  display: flex;
  min-height: $header-height;
  align-items: center;

  @include mq(md) {
    z-index: 101;
    height: $header-height;
    max-height: $header-height;
    border-bottom: $border $border-color;
  }
}

.site-title {
  @include container;
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: $sp-3;
  padding-bottom: $sp-3;
  color: $body-heading-color;
  @include fs-6;

  @include mq(md) {
    padding-top: $sp-2;
    padding-bottom: $sp-2;
  }
}

@if variable-exists(logo) {
  .site-logo {
    width: 100%;
    height: 100%;
    background-image: url($logo);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
}

.menu-button {
  appearance: none;
  display: flex;
  height: 100%;
  padding: $gutter-spacing-sm;
  align-items: center;
  color: $link-color;
  text-transform: uppercase;
  background: transparent;
  border: 0;

  @include mq(md) {
    display: none;
  }
}

// stylelint-disable selector-max-type

body {
  position: relative;
  padding-bottom: $sp-10;

  @include mq(md) {
    position: static;
    padding-bottom: 0;
  }
}

// stylelint-enable selector-max-type

.site-footer {
  @include container;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: $sp-4;
  padding-bottom: $sp-4;

  @include mq(md) {
    position: static;
    justify-self: end;
  }
}
