@charset "UTF-8";

//
// Styles for rendered markdown in the .main-content container
//
// stylelint-disable selector-no-type, max-nesting-depth, selector-max-compound-selectors, selector-max-type

.page-content {
  line-height: $content-line-height;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ul,
  ol {
    padding-left: 1.5em;
  }

  ol {
    list-style-type: none;
    counter-reset: step-counter;

    > li {
      position: relative;

      &::before {
        position: absolute;
        top: 0.2em;
        left: -1.6em;
        color: $grey-dk-000;
        content: counter(step-counter);
        counter-increment: step-counter;
        @include fs-3;

        @include mq(sm) {
          top: 0.11em;
        }
      }

      ol {
        counter-reset: sub-counter;

        li {
          &::before {
            content: counter(sub-counter, lower-alpha);
            counter-increment: sub-counter;
          }
        }
      }
    }
  }

  ul {
    list-style: none;

    > li {
      &::before {
        position: absolute;
        margin-left: -1.4em;
        color: $grey-dk-000;
        content: "•";
      }
    }
  }

  .task-list {
    padding-left: 0;
  }

  .task-list-item {
    display: flex;
    align-items: center;

    &::before {
      content: "";
    }
  }

  .task-list-item-checkbox {
    margin-right: 0.6em;
  }

  hr + * {
    margin-top: 0;
  }

  h1:first-of-type {
    margin-top: 0.5em;
  }

  dl {
    display: grid;
    grid-template-columns: max-content 1fr;
  }

  dt,
  dd {
    margin: 0.25em 0;
  }

  dt {
    text-align: right;

    &::after {
      content: ":";
    }
  }

  dd {
    margin-left: 1em;
    font-weight: 500;
  }

  .anchor-heading {
    position: absolute;
    right: -$sp-4;
    width: $sp-5;
    height: 100%;
    padding-right: $sp-1;
    padding-left: $sp-1;
    overflow: visible;

    @include mq(md) {
      right: auto;
      left: -$sp-5;
    }

    svg {
      display: inline-block;
      width: 100%;
      height: 100%;
      fill: $link-color;
      visibility: hidden;
    }
  }

  .anchor-heading:hover,
  h1:hover > .anchor-heading,
  h2:hover > .anchor-heading,
  h3:hover > .anchor-heading,
  h4:hover > .anchor-heading,
  h5:hover > .anchor-heading,
  h6:hover > .anchor-heading {
    svg {
      visibility: visible;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
  }
}
