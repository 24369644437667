//
// Main nav, breadcrumb, etc...
//
.navigation-list {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.navigation-list-link {
  color:$nav-child-link-color;
  font-weight: bold;
}

.navigation-list-child-list {
  padding-left: $sp-3;
  list-style: none;

  .navigation-list-link {
    color: $nav-child-link-color;
    font-weight: 300;
  }

  .navigation-list-item {
    position: relative;

    &::before {
      position: absolute;
      margin-top: 0.3em;
      margin-left: -0.8em;
      color: rgba($body-text-color, 0.3);
    }

    &.active {
      &::before {
        color: $body-text-color;
      }
    }
  }
}

.navigation-list-item {
  @include fs-4;
  margin: 0;

  @include mq(md) {
    @include fs-4;
  }
  
  .grand-child-list {
    display: none;
    @include fs-3;
  }
  .grand-grand-child-list {
    display: none;
    @include fs-3;
  }
  .grand-grand-grand-child-list {
    display: none;
    @include fs-3;
  }
  
  .grand-grand-grand-grand-child-list {
    display: none;
    @include fs-3;
  }
  .child-list {
    display: bock;
    @include fs-4;
  }


  &.active {
    .child-list {
      display: block;

      &.active{
        .grand-child-list{
          display: block;
          
          &.active{
            .grand-grand-child-list{
              display: block;
              
              &.active{
                .grand-grand-grand-child-list{
                  display: block;

                  &.active{
                    .grand-grand-grand-grand-child-list{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.navigation-list-link {
  display: block;
  padding-top: $sp-1;
  padding-bottom: $sp-1;
  //color: $nav-child-link-color;
  color: #616161;

  &.active {
    font-weight: 600;
    color: #44a8fa;
    text-decoration: none;
  }
}

// Small screen nav

.main-nav {
  display: none;

  &.nav-open {
    display: block;
  }
  @include mq(md) {
    display: block;
  }
}

.aux-nav {
  align-self: center;
}

// Breadcrumb nav
.breadcrumb-nav {
  @include mq(md) {
    margin-top: -$sp-4;
  }
}

.breadcrumb-nav-list {
  padding-left: 0;
  margin-bottom: $sp-3;
  list-style: none;
}

.breadcrumb-nav-list-item {
  display: table-cell;
  @include fs-2;

  &::before {
    display: none;
  }

  &::after {
    display: inline-block;
    margin-right: $sp-2;
    margin-left: $sp-2;
    color: $grey-dk-000;
    content: "/";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
}
